@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Light.woff2') format('woff2'),
        url('Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Bold.woff2') format('woff2'),
        url('Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Book.woff2') format('woff2'),
        url('Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Thin.woff2') format('woff2'),
        url('Gotham-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Medium.woff2') format('woff2'),
        url('Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Black.woff2') format('woff2'),
        url('Gotham-Black.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

